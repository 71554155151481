import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import Slider from "rc-slider";
import API from "API";
import styled from "styled-components";
import "rc-slider/assets/index.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class PitchModal extends Component {
  state = {
    userPitch: "",
    words: 150,
  };

  componentDidMount() {
    API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) =>
      this.setState(response.data)
    );
    let currentWordLength = this.state.userPitch.split(" ").length;
    this.setState({
      words: 150 - currentWordLength,
    });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    if (name === "userPitch") {
      let currentWordLength = this.state.userPitch.split(" ").length;
      this.setState({
        words: 150 - currentWordLength,
      });
    }

    if (name === "userPitch") {
      if (this.state.words <= 0) {
        this.setState({
          userPitch: value,
        });
      }
    }

    if (name === "userPitch") {
      if (this.state.words > 0) {
        let currentWordLength = this.state.userPitch.split(" ").length;
        this.setState({
          words: 150 - currentWordLength,
        });
      }
    }
  };

  handleSubmit = () => {
    API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
      userPitch: this.state.userPitch,
    }).then(() => {
      API.get(
        `/UserProfiles/${this.props.userToken.userId}?filter={
          "include": ["avatar", "userDesiredIndustry", "userDesiredCompanyType", "userDesiredJobLevel", "userDesiredCompanyTypes", "userDesiredIndustries", "employmentType" ]
        }`
      ).then((response) => {
        this.setState({
          userPitch: response.data.userPitch,
        });
      });
      this.props.getFreshData();
      this.props.closeModal();
    });
  };

  render() {
    return (
      <Modal
        show={this.props.displayModal === "pitch"}
        size="lg"
        dialogClassName="modal-90w"
        animation={false}
        centered
      >
        <Modal.Body>
          <Container>
            <ModalHeading>MyCare Pitch</ModalHeading>
            <p>
            Sell yourself to hiring partners in 150 words or less! Highlighting personal experiences, certifications, and achievements is a great place to start. What makes you stand out?
              (Required for your profile to be completed)
            </p>
            <Form>
              <Form.Row>
                <Col>
                  <Form.Label>Your pitch</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="15"
                    placeholder="A summary of your achievements and previous success"
                    name="userPitch"
                    maxlength={500}
                    onChange={this.handleChange}
                    value={this.state.userPitch}
                  />
                </Col>
              </Form.Row>
              <div>
                <p>Characters Remaining: {500 - this.state.userPitch.length}</p>
              </div>
              <Form.Row className="mt-2">
                <Button
                  className="  mr-1"
                  variant="outline-primary"
                  onClick={this.props.closeModal}
                >
                  Exit
                </Button>
                <Button className="ml-auto" onClick={this.handleSubmit}>
                  Update
                </Button>
              </Form.Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

export default PitchModal;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
`;

const ModalHeading = styled.h1``;
