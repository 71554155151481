import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { scroller } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import classes from './index.module.css';
import mycarepathPrivacy from '../../constants/mycarepathPrivacyPolicy';

function TemplateSupport(props) {
  const {
    contentHeroImg,
    contentHeroTitle1,
    contentHeroTitle2,
    contentHeroDescription,
    contentHeroButtonText,
    contentHeroButtonSupport,
    contentHeroButtonLink,
    recentlyAdded,
    contentBody,
  } = props;

  const scrollToBodyContent = () => {
    scroller.scrollTo('bodyContent', {
      duration: 800,
      delay: 0,
      offset: -90,
      smooth: 'easeInOutQuart',
    });
  };

  const bodyText = contentBody ? contentBody.map((item, index) => (
    <Grid
      container
      className={`${index === 0 ? 'bodyContent' : ''}`}
      style={{
        padding: '2.5rem 0',
        flexDirection: item.reverse ? 'row-reverse' : 'row',
      }}
    >
      <Grid
        item
        xs={2}
        sm={1}
        lg={2}
      />
      <Grid
        item
        xs={8}
        sm={4}
        lg={2}
        style={{
          display: 'flex',
          flexDirection: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundImage: `url(${item.avatarImg})`,
            backgroundPosition: `${item.avatarPosition}%`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '50%',
            height: '',
            width: '100%',
            aspectRatio: '1/1',
          }}
          className={classes.imgContainer}
        />
      </Grid>
      <Grid
        item
        xs={2}
        className={classes.gridSpace}
      />
      <Grid
        item
        xs={1}
        className={classes.gridSpace}
      />
      <Grid
        item
        xs={10}
        sm={6}
        className={classes.descriptionContainer}
      >
        {
          item.body ? item.body.map((cell) => {
            const builder = {
              text: (
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                  }}
                >
                  { cell.text }
                </Typography>
              ),
              spacedtext: (
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  { cell.text }
                </Typography>
              ),
              bold: (
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                  }}
                >
                  <strong>{ cell.text }</strong>
                </Typography>
              ),
              linebreak: (
                <br />
              ),
            };
            return builder[cell.type];
          }) : null
        }
      </Grid>
    </Grid>
  )) : null;

  const landingContent = (
    <>
      <Grid
        container
        className={classes.heroContainer}
      >
        <Grid
          className={classes.heroImgContainer}
          item
          container
          xs={12}
        >
          <div
            style={{
              backgroundImage: `url(${contentHeroImg})`,
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: '15%',
              transform: 'scaleX(-1)',
            }}
          />
          <Grid
            item
            xs={0}
            sm={1}
            lg={2}
            className={classes.heroContainerItems}
          />
          <Grid
            item
            xs={12}
            sm={10}
            lg={4}
            className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
          >
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle1 }
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle2 }
            </Typography>
            <Typography
              style={{
                color: '#fff',
                margin: '20px 0',
              }}
            >
              { contentHeroDescription }
            </Typography>
            <Button
              className={classes.buttonSquareFilledRed}
              onClick={() => scrollToBodyContent()}
              style={{
                margin: '20px 0',
                minWidth: '200px',
                height: '44px',
              }}
            >
              { contentHeroButtonSupport }
            </Button>
          </Grid>
        </Grid>
      </Grid>
      { bodyText }
      <Grid
        container
        style={{
          padding: '2.5rem 0',
        }}
      >
        <Grid
          item
          xs={1}
          lg={2}
        />
        <Grid
          item
          xs={10}
          lg={8}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Button
            className={classes.buttonSquareFilledRed}
            href={contentHeroButtonLink}
            target="_blank"
            rel="noreferrer"
            style={{
              margin: '20px 0',
              boxShadow: '0 15px 15px -6px #0003',
              minWidth: '200px',
              height: '44px',
              letterSpacing: '2px',
            }}
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.recentContainer}
      >
        <Grid
          item
          xs={0}
          lg={2}
        />
        <Grid
          item
          xs={12}
          lg={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              padding: '1.25rem 0',
              color: '#4d4d4d',
            }}
          >
            Recently Joined
          </Typography>
          <div
            className={classes.smallHorizontalLine}
          />
          <div
            className={classes.avatarSection}
          >
            {
              recentlyAdded ? recentlyAdded.map((item) => (
                <div
                  className={classes.avatarContainer}
                >
                  <div
                    className={classes.avatarBorder}
                  />
                  <img
                    className={classes.avatarItem}
                    src={item}
                    alt=""
                  />
                </div>
              )) : null
            }
          </div>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid
      container
      className={classes.contentContainer}
    >
      {
        landingContent
      }
    </Grid>
  );
}

TemplateSupport.propTypes = {};

export default TemplateSupport;
