import React from 'react'
import PropTypes from 'prop-types'

import NavMenuTemplate from '../NavMenuTemplate';
import PrivacyContentTemplate from '../PrivacyContentTemplate';

function PrivacyBodyTemplate(props) {
  return (
    <>
      <NavMenuTemplate
        {...props}
      />
      <PrivacyContentTemplate
        {...props}
      />
    </>
  )
}

PrivacyBodyTemplate.propTypes = {}

export default PrivacyBodyTemplate
