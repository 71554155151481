import React from "react";
import { Route } from "react-router-dom";
import LoginScreen from "LoginScreen";
import LandingScreen from "LandingScreen";

const isAuthenticated = () => {
  const userToken = localStorage.getItem("userToken");
  if (userToken) {
    return userToken;
  } else {
    return false;
  }
};

const RootRoute = ({ component: Component, ...rest }) => {
  let userToken = JSON.parse(isAuthenticated());
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} userToken={userToken} />
        ) : (
          <LandingScreen {...props} />
        )
      }
    />
  );
};

export default RootRoute;
