import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

const isAuthenticated = () => {
  const userToken = localStorage.getItem("userToken");
  if (userToken) {
    return userToken;
  } else {
    return false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  let userToken = JSON.parse(isAuthenticated());
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} userToken={userToken} />
        ) : (
          <Redirect to="/getstarted" />
        )
      }
    />
  );
};

export default PrivateRoute;
