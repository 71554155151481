import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import { ReactComponent as LockIcon } from "images/icons/Lock.svg";

class PasswordSettingsScreen extends Component {
  render() {
    return (
      <Container>
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <SettingsContainer>
              <SettingsHeading>
                <LockIcon />
                <SettingsTitle>Password</SettingsTitle>
              </SettingsHeading>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Label>Current password</Form.Label>
                    <Form.Control type="text" placeholder="Email" />
                  </Col>
                </Form.Row>
                <Form.Row className="mt-3">
                  <Col>
                    <Form.Label>New password</Form.Label>
                    <Form.Control type="text" placeholder="Name" />
                  </Col>
                  <Col>
                    <Form.Label>Confirm new Password</Form.Label>
                    <Form.Control type="text" placeholder="Username" />
                  </Col>
                </Form.Row>
                <Form.Row className="mt-4">
                  <Col>
                    <Button
                      variant="outline-primary float-right"
                      onClick={this.incrementStep}
                    >
                      Save
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            </SettingsContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

const SettingsContainer = styled.div`
  background: white;
  border: 3px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  padding: 40px;
`;
const SettingsHeading = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;
const SettingsTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
`;

export default PasswordSettingsScreen;
