import React from "react";
import styled from "styled-components";

export default {
  title: "Buttons",
};

export const PrimaryButton = styled.button`
  border-radius: 50px;
  color: white;
  background: #BB0404;
  padding: 10px;
  font-weight: bold;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  border: 1px solid #BB0404;
  :hover {
    background: white;
    border: 1px solid #BB0404;
    color: #BB0404;
  }
`;

export const StoryPrimaryButton = () => <PrimaryButton>Button</PrimaryButton>;
