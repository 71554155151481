import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import API from "API";

class EmailVerificationScreen extends Component {
  state = {};
  componentDidMount() {
    API.get(`/UserEmailVerificationCodes/findOne?filter={"where": {"code": "${this.props.match.params.emailVerificationId}"}}`).then((response) => {
      let userProfileId = response.data.userProfileId;
      API.patch(`/UserProfiles/${userProfileId}`, {
        emailVerified: true,
      }).then((response) => {
        this.props.history.push("/getstarted");
      });
    });
  }
  render() {
    return (
      <Container>
        <Row>
          <Col>Please Wait - Verifying Email...</Col>
        </Row>
      </Container>
    );
  }
}

export default EmailVerificationScreen;
