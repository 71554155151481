import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { scroller } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import classes from './index.module.css';
import mycarepathPrivacy from '../../constants/mycarepathPrivacyPolicy';
import { signUpRequest } from '../../constants/endpoints';

const useStyles = makeStyles({
  select: {
    width: '90%',
    padding: '8.5px 14px',
  },
  popoverRoot: {
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
  },
});

function TemplateInfo(props) {
  const {
    contentHeroImg,
    contentHeroTitle1,
    contentHeroTitle2,
    contentHeroDescription,
    contentHeroButtonText,
    contentHeroButtonLink,
    recentlyAdded,
    contentBody,
    privacyForm,
    changePrivacyForm,
  } = props;
  const [firstName, changeFirstName] = useState('');
  const [lastName, changeLastName] = useState('');
  const [emailAddress, changeEmailAddress] = useState('');
  const [emailValidation, changeEmailValidation] = useState(false);
  const [phoneNumber, changePhoneNumber] = useState('');
  const [selectedOption, changeSelectedOption] = useState('Please select an option');
  const [openOtherOption, changeOpenOtherOption] = useState(false);
  const [otherOptionField, changeOtherOptionField] = useState('');
  const [confirmationForm, changeConfirmationForm] = useState(false);
  const styles = useStyles();

  const scrollToBodyContent = () => {
    scroller.scrollTo('bodyContent', {
      duration: 800,
      delay: 0,
      offset: -90,
      smooth: 'easeInOutQuart',
    });
  };

  const handleOtherOptionOpen = () => changeOpenOtherOption(true);
  const handleOtherOptionClose = () => changeOpenOtherOption(false);

  const handleChangePrivacyForm = () => {
    changePrivacyForm(!privacyForm);
  };

  const handleFormFieldsClear = () => {
    changeFirstName('')
    changeLastName('')
    changeEmailAddress('')
    changePhoneNumber('')
    changeSelectedOption('Please select an option')
  }

  const handleEmailValidation = () => {
    const regex = /\w+[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,3}/;
    if (!regex.test(emailAddress)) {
      changeEmailValidation(true);
      return null;
    }
    const request = {
      method: 'POST',
      data: {
        firstName,
        lastName,
        address: openOtherOption ? otherOptionField : selectedOption,
        email: emailAddress,
        phoneNumber,
      },
    };
    signUpRequest(request);
    changeConfirmationForm(true);
  };

  const handleFormClose = () => {
    changeConfirmationForm(false);
    changeOpenOtherOption(false);
    handleFormFieldsClear()
  };

  const privacyContent = (
    <Grid
      container
      style={{
        minHeight: '100vh',
        backgroundColor: '#ccc',
      }}
    >
      <Grid
        item
        xs={1}
      />
      <Grid
        className={classes.privacyInnerContainer}
        item
        xs={10}
      >
        <div
          className={classes.privacyBackButton}
          onClick={() => handleChangePrivacyForm()}
        >
          <ArrowBackIcon />
          <Typography
            style={{
              marginLeft: '10px',
            }}
          >
            Back
          </Typography>
        </div>
        { mycarepathPrivacy() }
      </Grid>
    </Grid>
  );

  const signUpForm = (
    <div
      className={classes.formSignUp}
    >
      <Typography
        variant="h2"
        style={{
          color: '#A2171F',
          fontSize: '2.25rem',
          fontFamily: 'Open Sans',
          fontWeight: '700',
        }}
      >
        Sign Up Now
      </Typography>
      <Typography
        style={{
          margin: '10px 0',
          fontSize: '1rem',
          fontFamily: 'Open Sans',
          fontWeight: '400',
          textAlign: 'center',
        }}
      >
        Are you looking for a new opportunity or in need of diverse talent?
      </Typography>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <TextField
            size="small"
            placeholder="First Name"
            variant="outlined"
            onChange={(e) => changeFirstName(e.target.value)}
            value={firstName}
            style={{
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <TextField
            size="small"
            placeholder="Last Name"
            variant="outlined"
            onChange={(e) => changeLastName(e.target.value)}
            value={lastName}
            style={{
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            size="small"
            placeholder="Email Address"
            variant="outlined"
            onChange={(e) => {
              changeEmailAddress(e.target.value);
              changeEmailValidation(false);
            }}
            value={emailAddress}
            label={emailValidation ? 'Please use a valid email' : null}
            error={emailValidation}
            style={{
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            size="small"
            placeholder="Phone Number"
            variant="outlined"
            onChange={(e) => changePhoneNumber(e.target.value)}
            value={phoneNumber}
            style={{
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Select
            size="small"
            variant="outlined"
            onChange={(e) => changeSelectedOption(e.target.value)}
            value={selectedOption}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span>Please select an option</span>;
              }

              return selected;
            }}
            classes={{
              select: styles.select,
            }}
            style={{
              display: 'block',
            }}
          >
            <MenuItem disabled value="">
              Please select an option
            </MenuItem>
            <MenuItem
              value="I’m a DSP and want to use this to help me connect to potential career opportunities"
              onClick={() => handleOtherOptionClose()}
            >
              I’m a DSP and want to use this to help me connect to potential career opportunities
            </MenuItem>
            <MenuItem
              value="I’m interested in using MyCarePath to hire DSPs"
              onClick={() => handleOtherOptionClose()}
            >
              I’m interested in using MyCarePath to hire DSPs
            </MenuItem>
            <MenuItem
              value="I’m a DSP Recruiter"
              onClick={() => handleOtherOptionClose()}
            >
              I’m a DSP Recruiter
            </MenuItem>
            <MenuItem
              value="Other (Please Share)"
              onClick={() => handleOtherOptionOpen()}
            >
              Other (Please Share)
            </MenuItem>
          </Select>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            size="small"
            placeholder="Other Option"
            variant="outlined"
            onChange={(e) => changeOtherOptionField(e.target.value)}
            value={otherOptionField}
            style={{
              display: openOtherOption ? 'inline-flex' : 'none',
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.formSignUpButton}
        >
          <Button
            className={classes.buttonSquareFilledRed}
            onClick={() => handleEmailValidation()}
            style={{
              margin: '20px 0',
              minWidth: '200px',
              height: '44px',
            }}
          >
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  const bodyText = (
    <Grid
      className="bodyContent"
      container
      style={{
        margin: '2.5rem 0 ',
      }}
    >
      <Grid
        container
      >
        <Grid
          item
          xs={12}
          className={classes.bodyTitleContainer}
        >
          <Typography
            variant="h2"
            className={classes.bodyTitle}
          >
            Ways the MyCarePath Platform Can Benefit You
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            marginTop: '6.25rem',
            marginBottom: '3.125rem',
          }}
        >
          <Grid
            item
            xs={0}
            lg={2}
          />
          <Grid
            item
            container
            xs={12}
            lg={8}
          >
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/message-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                  }}
                >
                  Entertain conversations about potential career advancement without jeopardizing your current role
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/bag-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                  }}
                >
                  Have real conversations with hiring companies that express interest in you —no résumé required
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/collab-hands-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                  }}
                >
                  Anonymity in the platform will remove all implicit bias and discrimination in the hiring process
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/megaphone-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                  }}
                >
                  Stay informed and up to date with the latest information
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const landingContent = (
    <>
      <Grid
        container
        className={classes.heroContainer}
      >
        <Grid
          className={classes.heroImgContainer}
          item
          container
          xs={12}
        >
          <div
            style={{
              backgroundImage: `url(${contentHeroImg})`,
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: '15%',
            }}
          />
          <Grid
            item
            xs={0}
            sm={1}
            lg={2}
            className={classes.heroContainerItems}
          />
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={4}
            className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
          >
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle1 }
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle2 }
            </Typography>
            <Typography
              className={classes.heroDescription}
            >
              { contentHeroDescription }
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            lg={3}
            className={classes.formSignUpDesktop}
            style={{
              alignItems: 'flex-end',
              zIndex: '10',
            }}
          >
            { signUpForm }
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.formSignUpMobile}
        style={{
          margin: '20px 0',
          alignItems: 'flex-end',
          zIndex: '10',
        }}
      >
        { signUpForm }
      </Grid>
      { bodyText }
      <Grid
        container
        style={{
          padding: '2.5rem 0',
        }}
      >
        <Grid
          item
          xs={1}
          lg={2}
        />
        <Grid
          item
          xs={10}
          lg={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              fontSize: '1.438rem',
              fontFamily: 'Open Sans',
              fontWeight: '700',
            }}
          >
            Are you interested in hiring talent from our community?
          </Typography>
          <Button
            className={classes.buttonSquareFilledRed}
            href={contentHeroButtonLink}
            target="_blank"
            rel="noreferrer"
            style={{
              margin: '20px 0',
              width: '200px',
              height: '44px',
            }}
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid
      container
      className={classes.contentContainer}
    >
      {
        privacyForm ? privacyContent : landingContent
      }
      <Popover
        open={confirmationForm}
        onClose={handleFormClose}
        classes={{
          paper: styles.popoverRoot,
        }}
        style={{
          overflowY: 'scroll',
          height: '100%',
          width: '100%',
          background: 'rgba(0,0,0,0.2)',
          boxShadow: 'none',
        }}
        disableScrollLock
      >
        <div
          className={classes.popupContainer}
        >
          <Typography
            variant="h2"
            className={classes.popupTitle}
          >
            Thank you for signing up to speak with a MyCarePath Hiring Expert
          </Typography>
          <Typography
            style={{
              padding: '20px 0',
              fontSize: '1rem',
              fontFamily: 'Open Sans',
            }}
          >
            Someone from the MyCarePath Team will reach out to you soon to discuss your hiring needs
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              '@media (max-width: 601px)': {
                justifyContent: 'center'
              }
            }}
          >
            <Button
              className={classes.buttonSquareFilledRed}
              onClick={() => handleFormClose()}
              style={{
                margin: '20px 0',
                minWidth: '200px',
                height: '44px',
              }}
            >
              Exit
            </Button>
          </div>
        </div>
      </Popover>
    </Grid>
  );
}

TemplateInfo.propTypes = {};

export default TemplateInfo;
