import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import MessagesIcon from "@material-ui/icons/Forum";
import MatchesIcon from "@material-ui/icons/Beenhere";
import ProfileIcon from "@material-ui/icons/AccountBox";

const useStyles = makeStyles({
  root: {
    width: 500,
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <MobileOnlyView
      style={{
        marginTop: 66,
      }}
    >
      <BottomNavigation
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
        }}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction
          component={Link}
          to="/getstarted"
          label="Home"
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="/mobile/messages/list"
          label="Messages"
          icon={<MessagesIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="/matches"
          label="Matches"
          icon={<MatchesIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="/profile"
          label="Profile"
          icon={<ProfileIcon />}
        />
      </BottomNavigation>
    </MobileOnlyView>
  );
}
