import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class LogoutScreen extends Component {
  componentDidMount() {
    localStorage.removeItem("userToken");
    this.props.history.push("/getstarted");
  }

  render() {
    return <div />;
  }
}

export default LogoutScreen;
