import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import API from "API";

const isAuthenticated = () => {
  const userToken = localStorage.getItem("userToken");
  if (userToken) {
    return userToken;
  } else {
    return false;
  }
};

const RecruiterRoute = ({ component: Component, ...rest }) => {
  let userToken = JSON.parse(isAuthenticated());
  let recruiterEnabled = true;
  API.get(`/UserProfiles/${userToken.userId}`).then((response) => {
    recruiterEnabled = response.data.recruiterEnabled;
  });
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} userToken={userToken} />
        ) : (
          <Redirect to="/getstarted" />
        )
      }
    />
  );
};

export default RecruiterRoute;
