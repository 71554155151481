import React from 'react'
import PropTypes from 'prop-types'

import NavMenuTemplate from '../NavMenuTemplate';
import SupportBodyTemplate from '../SupportBodyTemplate';


function SupportLandingTemplate(props) {
  return (
    <>
      <NavMenuTemplate
        {...props}
      />
      <SupportBodyTemplate
        {...props}
      />
    </>
  )
}

SupportLandingTemplate.propTypes = {}

export default SupportLandingTemplate
