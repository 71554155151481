import React, { Component } from "react";
import { Container, Row, Col, Button, Jumbotron } from "react-bootstrap";
import { Link } from "react-router-dom";

class ResetPasswordThankYouScreen extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Jumbotron>
              <h1>Thank You</h1>
              <p>
                Please check your email for a special link to reset your
                account's password.
              </p>
              <Link to="/getstarted">
                <Button>Go To Home Page</Button>
              </Link>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ResetPasswordThankYouScreen;
