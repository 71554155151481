import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import API from "API";

class CreateNewPasswordScreen extends Component {
  state = {
    password: "",
    repeatPassword: "",
  };

  componentDidMount() {
    API.noOptionsGet(
      `/UserResetPasswordCodes/findOne?filter={
            "where": {
                "code": "${this.props.match.params.code}"
            }
        }`
    )
      .then((response) => {})
      .catch((err) => {
        this.props.history.push("/getstarted");
      });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    API.noOptionsPost(`/UserProfiles/resetMyPassword`, {
      code: this.props.match.params.code,
      password: this.state.password,
    })
      .then((response) => {
        this.props.history.push("/getstarted");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={6}>
            <h1>Reset Password</h1>
            <Form>
              <Form.Group>
                <Form.Label>Create A New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  We'll never share your password with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Re-enter password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Repeat password"
                  name="repeatPassword"
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  Just type your password again to make sure we have it correct.
                </Form.Text>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CreateNewPasswordScreen;
