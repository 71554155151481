import React from 'react';
import PropTypes from 'prop-types';

import NavMenuTemplate from '../NavMenuTemplate';
import RootBodyTemplate from '../RootBodyTemplate';

function RootLandingTemplate(props) {
  return (
    <>
      <NavMenuTemplate
        {...props}
      />
      <RootBodyTemplate
        {...props}
      />
    </>
  )
}

RootLandingTemplate.propTypes = {}

export default RootLandingTemplate
