import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';

import classes from './index.module.css';

function TemplateFooter(props) {
  return (
    <footer
      className={classes.footerContainer}
    >
      <Grid
        container
        className={classes.footerGrid}
      >
        <Grid
          item
          xs={0}
          lg={2}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={3}
        >
          <Link
            to="/privacy-policy"
            className={classes.footerLink}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LockIcon
              style={{
                fill: '#fff',
              }}
            />
            <Typography
              style={{
                color: '#fff',
                fontSize: '0.875rem',
                fontFamily: 'Open Sans',
                marginRight: '10px',
              }}
            >
              Privacy Policy
            </Typography>
          </Link>
        </Grid>
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={2}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={3}
        >
          <Typography
            className={classes.footerGogigTypography}
          >
            Powered by
          </Typography>
          <img
            className={classes.footerGogigLogo}
            src="/icons/gogig-logo.svg"
            alt=""
          />
        </Grid>
        <Grid
          item
          xs={0}
          lg={2}
        />
      </Grid>
    </footer>
  );
}

TemplateFooter.propTypes = {};

export default TemplateFooter;
